// extracted by mini-css-extract-plugin
export var arrowAnimation = "Progress-module--arrowAnimation--7c7fb";
export var cheerImg = "Progress-module--cheerImg--2b338";
export var concerns = "Progress-module--concerns--8dbfc";
export var newBtn = "Progress-module--newBtn--c68a9";
export var progressHeading = "Progress-module--progressHeading--1fc93";
export var progressSec = "Progress-module--progressSec--0c81c";
export var racinoConcerns = "Progress-module--racinoConcerns--e4b7a";
export var racinoProgressSec = "Progress-module--racinoProgressSec--ce9da";
export var racinoprogressHeading = "Progress-module--racinoprogressHeading--c531b";
export var talkBtn = "Progress-module--talkBtn--aa46f";