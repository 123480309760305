import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./Banner1.module.scss"

const BannerSlider = ({ strapiData, predicto }) => {
  return (
    <div className={styles.dedicatedBanner}>
      <div className={styles.bannerText}>
        <Container>
          <Row>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={3}
              className={styles.headingContainer}
            >
              <h1
                className={styles.headingIntro}
                dangerouslySetInnerHTML={{ __html: strapiData?.title }}
              />
            </Col>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={9}
              className={styles.headingContainer}
            >
              <p
                className={styles.paragraphAi}
                dangerouslySetInnerHTML={{ __html: strapiData?.subTitle }}
              />
            </Col>
          </Row>
        </Container>
      </div>
      <Container className={styles.innerDedicated}>
        <div className={styles.dedicated}>
          <img
            src={
              "https://invozone-backend.s3.us-east-1.amazonaws.com/image_344_d9b081ad38.webp"
            }
            alt="bg image"
            className={`${styles.backgroundImg} ${styles.desktopImage}`}
          />

            <img
              src="https://invozone-backend.s3.us-east-1.amazonaws.com/Frame_1991422498_17b184ae8e.webp"
              alt="mobile bg image"
              className={`${styles.backgroundImg} `}
            />
    
        </div>
      </Container>
      <img
        className={styles.bannerCurve}
        src="https://invozone-backend.s3.us-east-1.amazonaws.com/line_vector_c415196118.png"
        alt="curve"
      />
    </div>
  )
}

export default BannerSlider
