import React from "react"
import Container from "react-bootstrap/Container"
import * as styles from "./CenterImage.module.scss"

function Conclusion() {
  return (
    <Container>
      <div className={styles.laptopImg}>
        <img
          src={
            "https://invozone-backend.s3.us-east-1.amazonaws.com/Group_1686557388_48dd9533fa.webp"
          }
          alt="laptop"
          decoding="async"
          loading="lazy"
        />
      </div>
    </Container>
  )
}

export default Conclusion
