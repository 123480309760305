import React from "react"
import * as styles from "./Result.module.scss"
import { Container, Row } from "react-bootstrap"

function Enhancements({ strapiData, cheer }) {
  return (
    <div className={`${styles.enhancements} ${cheer ? styles.cheerPad : ""}`}>
      <Container>
        <h2 dangerouslySetInnerHTML={{ __html: strapiData?.title }}></h2>
        <Row>
          <div className={styles.cardsInner}>
            {strapiData?.cards?.map((e, i) => (
              <div
                className={styles.cards}
                style={{
                  borderTopLeftRadius: i === 0 || i === 3 ? "12px" : undefined,
                  borderBottomLeftRadius:
                    i === 0 || i === 3 ? "12px" : undefined,
                  borderTopRightRadius: i === 2 || i === 5 ? "12px" : undefined,
                  borderBottomRightRadius:
                    i === 2 || i === 5 ? "12px" : undefined,
                }}
              >
                <span>0{i + 1}</span>
                <h3
                  dangerouslySetInnerHTML={{
                    __html: e?.title,
                  }}
                ></h3>

                <p
                  className={styles.mainDes}
                  dangerouslySetInnerHTML={{
                    __html: e?.subTitle,
                  }}
                />
              </div>
            ))}
          </div>
        </Row>
      </Container>
    </div>
  )
}

export default Enhancements
