import { Link } from "gatsby"
import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./Progress.module.scss"

const Progress = ({ racino }) => {
  return (
    <div
      className={`${styles.progressSec} ${
        racino ? styles.racinoProgressSec : ""
      }`}
    >
      <Container>
        <div
          className={`${styles.concerns} ${
            racino ? styles.racinoConcerns : ""
          }`}
        >
          <Row className="justify-content-between align-items-center">
            <h2
              className={`${styles.progressHeading} ${
                racino ? styles.racinoprogressHeading : ""
              }`}
            >
              {racino
                ? "Wanna build your project? "
                : "Estimate a Prototype or Get a Product Development Roadmap"}
            </h2>

            <div className={styles.cardFooter}>
              <Link
                to={"/contact-us/"}
                className="newBtn"
                style={{ color: "white" }}
              >
                {racino ? "Get in touch with us! " : "Schedule A Call"}
              </Link>
            </div>
          </Row>
        </div>
        <div className={styles.cheerImg}>
          <img
            src={
              racino
                ? "https://invozone-backend.s3.us-east-1.amazonaws.com/Group_1686557431_9ddf595e57.webp"
                : "https://invozone-backend.s3.us-east-1.amazonaws.com/Group_1686557389_b487293bd4.webp"
            }
            alt="laptop"
            decoding="async"
            loading="lazy"
          />
        </div>
      </Container>
    </div>
  )
}

export default Progress
